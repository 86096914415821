<template>
  <div class="relative">
    <div class="d-none d-lg-flex justify-content-end">
      <div class="register-image">
        <img :src="areaItem.media.general[0].image" v-if="areaItem.media.general[0]" alt="">
        <div class="register-bg-video" v-if="areaItem.media.videos">
          <iframe :src="'https://player.vimeo.com/video/'+ areaItem.media.videos[0].videoThumbnail[0].id +'?background=1&autoplay=1&muted=1&loop=1&byline=0&title=0'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="register-content-wrapper">
      <div class="auth-register-header">
        <div class="container">
          <div class="d-flex justify-content-center align-items-center justify-content-lg-start relative">
            <router-link to="/register-complete" class="header-arrow d-lg-none">
              <chevronLeft/>
            </router-link>
            <div class="auth-register-header-logo">
              <router-link to="/">
                <LogoBoxSVG/>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="register-content-holder">
        <div class="container">
          <div class="register-content">
            <router-link to="/register-complete" class="header-arrow d-none d-lg-flex">
              <chevronLeft/>
            </router-link>
            <div class="progress-bar-wrapper">
              <div class="progress-bar-track">
                <div class="progress-bar" style="width: 66.666%;"></div>
              </div>
            </div>

            <RegisterObjectives/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo-black";
import chevronLeft from "@/components/svg/chevron-left.vue";
import RegisterObjectives from "@/components/auth/register-objectives";
import axios from "axios";

export default {
  data() {
    return {
      lang: this.$root.siteLang,
      areaSEO: [],
      areaItem: [],
      titleSEO: "",
      descriptionSEO: "",
      keywordsSEO: "",
    }
  },
  components: {
    LogoBoxSVG,
    chevronLeft,
    RegisterObjectives,
  },
  created() {
    //Background
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cp_29?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.areaItem = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
};
</script>